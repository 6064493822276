import { observer } from 'mobx-react-lite'
import { FC, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { ReactComponent as IconNotifications } from '../../assets/notifications.svg'
import { useMst } from '../../stores/store'
import ButtonImage from '../shared/button-image'

const NotificationBell: FC = observer(() => {
    const { notificationCenter } = useMst()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const onClick = useCallback(async () => {
        await notificationCenter.markAsRead()
        navigate('/me/notifications')
    }, [])

    useEffect(() => {
        notificationCenter.load().then(() => {
            const notifications = notificationCenter.notifications.filter(notification => !notification.read).length
            const hasNotifications = notifications > 0
            const links = document.querySelectorAll('link[rel~=icon]')
            if (!links) {
                return
            }
            // biome-ignore lint/complexity/noForEach: <explanation>
            links.forEach((link: HTMLLinkElement) => {
                if (hasNotifications) {
                    const ext = link.href.split('.').pop()
                    link.href = link.href.replace(`.${ext}`, `-red.${ext}`)
                } else {
                    link.href = link.href.replace(/-red/, '')
                }
            })

            // if (hasNotifications) {
            //     Notification.requestPermission().then(permission => {
            //         if (permission !== 'granted') {
            //             return
            //         }
            //
            //         let title = t('web_new_notifications_title', { count: notifications })
            //         let body = t('web_new_notifications_body', { count: notifications })
            //
            //         if (notifications === 1) {
            //             title = notificationCenter.notifications[0].title
            //             body = notificationCenter.notifications[0].content
            //         }
            //
            //         new Notification(title, {
            //             body: body,
            //         })
            //     })
            // }
        })
    }, [notificationCenter.notifications])

    return (
        <ButtonImage
            onClick={onClick}
            image={<IconNotifications className="h-7 w-7" />}
            notify={notificationCenter.notifications.filter(notification => !notification.read).length > 0}
            tooltip={t('web_tooltips_notifications')}
        />
    )
})

export default NotificationBell
