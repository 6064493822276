import { createContext } from 'react'

export interface AppConfig {
    redirect?: string
    setRedirect: (redirect: string) => void
    loginRegister: {
        readOnlyEmail: boolean
        setReadOnlyEmail: (readonly: boolean) => void
        email?: string
        setEmail: (email: string) => void
    }
}

export const AppContext = createContext<AppConfig>({
    setRedirect: (redirect: string) => {},
    loginRegister: {
        readOnlyEmail: false,
        setReadOnlyEmail: (readonly: boolean) => {},
        setEmail: (email: string) => {},
    },
})
