import { mapOrJsonOptions } from 'core/utils/map-to-object'
import { DateTime } from 'luxon'
import { toJS } from 'mobx'
import { applySnapshot, flow, getRoot, Instance, types } from 'mobx-state-tree'
import { del, get, HttpError, post, put } from '../core/services/http-service'
import toast from '../core/utils/toast'
import { _Brand, _Franchise } from './brands'
import { _Partner, _SmallPartner, Partner } from './partners'
import { _Pricing } from './pricing'
import { RootInstance } from './store'
import { _UserType } from './userType/user-type'

export const passwordRegex =
    /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]){1,}).{8,}$/

interface Token {
    refreshToken: string
    token: string
}

const _Tag = types.model('Tag', {
    uuid: '',
    name: '',
})

export interface CollaborationUserType {
    uuid: string
    username: string
    fullname: string
    picture: string | null
}

interface JsonUserType {
    id: string
    username: string
    firstname: string
    lastname: string
    email: string
    twoFa: boolean
    twoFaMethod: string
    checked: boolean
    locale: string
    active: boolean
    picture: string
    gdpr: boolean
    cgu: boolean
    hasNewCgu: boolean

    // read-only stuff
    readOnly: boolean
    canManagePersonalData: boolean
    secondaryEmail: string | null
    forwardEmail: string | null
}
interface JsonApiUserType {
    user: JsonUserType
}

export enum NotificationFrequency {
    live = 'live',
    none = 'none',
    once_every_hour = 'once_every_hour',
    once_every_day = 'once_every_day',
}

const _FirebaseBlacklist = types.model('FirebaseBlacklist', {
    namespace: '',
    type: '',
    frequency: types.optional(
        types.enumeration(Object.keys(NotificationFrequency)),
        NotificationFrequency.once_every_hour,
        [null, undefined]
    ),
})
export type FirebaseBlacklist = Instance<typeof _FirebaseBlacklist>

export const _SsoUser = types.model('SsoUser', {
    id: '',
    email: '',
    fullname: types.optional(types.string, '', [null, undefined]),
    picture: types.optional(types.string, '', [null, undefined]),
    ssoMethod: types.optional(types.string, '', [null, undefined]),
})
export type SsoUser = Instance<typeof _SsoUser>

export const _TribeUser = types.model('TribeUser', {
    id: '',
    email: '',
    fullname: types.optional(types.string, '', [null, undefined]),
    picture: types.optional(types.string, '', [null, undefined]),
    master: types.optional(types.boolean, false, [null, undefined]),
    storage: 0,
})
export type TribeUser = Instance<typeof _TribeUser>

export const _TribeInvitation = types.model('TribeInvitation', {
    email: '',
    status: types.enumeration(['pending', 'accepted', 'declined']),
})
export type TribeInvitation = Instance<typeof _TribeInvitation>

export const _Tribe = types.model('Tribe', {
    uuid: '',
    master: types.optional(types.boolean, false, [null, undefined]),
    maxMembers: 0,
    members: types.optional(types.array(_TribeUser), [], [null, undefined]),
    invitations: types.optional(types.array(_TribeInvitation), [], [null, undefined]),
})
export type Tribe = Instance<typeof _Tribe>

export const _UserAccountUser = types.model('UserAccountUser', {
    uuid: '',
    fullname: types.optional(types.string, '', [null, undefined]),
    email: types.optional(types.string, '', [null, undefined]),
    picture: types.optional(types.string, '', [null, undefined]),
})
export const _UserAccount = types.model('UserAccount', {
    uuid: '',
    accounts: types.array(_UserAccountUser),
})

export const _User = types
    .model('User', {
        id: '',
        username: '',
        firstname: types.optional(types.string, '', [null, undefined]),
        lastname: types.optional(types.string, '', [null, undefined]),
        fullname: types.optional(types.string, '', [null, undefined]),
        birthdate: types.optional(types.string, '', [null, undefined]),
        phone: types.optional(types.string, '', [null, undefined]),
        email: types.optional(types.string, '', [null, undefined]),
        timeBeforeDeactivation: types.optional(types.number, -1, [null, undefined]),
        checked: false,
        locale: 'fr',
        role: types.optional(types.string, '', [null, undefined]),
        active: false,
        picture: types.optional(types.string, '', [null, undefined]),
        ssoMethod: types.optional(types.string, '', [null, undefined]),
        widget: types.optional(types.string, 'base', [null, undefined]),
        gdpr: false,
        cgu: false,
        hasNewCgu: false,
        password: '',
        changedPassword: '',
        newPassword: false,
        twoFa: false,
        twoFaMethod: types.optional(types.string, '', [null, undefined]),
        firebaseBlacklist: types.optional(types.array(_FirebaseBlacklist), [], [null, undefined]),
        ssos: types.array(_SsoUser),
        tags: types.array(_Tag),
        account: types.optional(_UserAccount, { accounts: [] }, [null, undefined]),
        deleted: false,
        deletedAt: types.optional(types.string, '', [null, undefined]),
        deletedBy: types.optional(types.string, '', [null, undefined]),
        createdAt: types.optional(types.number, 0, [null, undefined]),
        accountBlockAt: types.optional(types.number, 0, [null, undefined]),
        accountDeleteAt: types.optional(types.number, 0, [null, undefined]),
        partnerUuid: types.optional(types.string, '', [null, undefined]),
        partner: types.optional(
            types.late(() => _Partner),
            {},
            [null, undefined]
        ),
        pricing: types.optional(_Pricing, {}, [null, undefined]),
        managingPartners: types.array(types.late(() => _SmallPartner)),
        manager: types.optional(_SsoUser, {}, [null, undefined]),
        userType: types.optional(_UserType, {}, [null, undefined]),
        confidential: false,
        street: types.optional(types.string, '', [null, undefined]),
        zip: types.optional(types.string, '', [null, undefined]),
        number: types.optional(types.string, '', [null, undefined]),
        city: types.optional(types.string, '', [null, undefined]),
        country: types.optional(types.string, '', [null, undefined]),
        ocrStatus: types.optional(types.string, 'not_configured', [null, undefined]),
        coordinates: types.optional(types.string, '', [null, undefined]),
        tribe: types.optional(_Tribe, {}, [null, undefined]),
        stacks: types.optional(types.array(types.string), ['stack'], [null, undefined]),

        //currentBrand: types.optional(types.late(() => _Brand), {}, [null, undefined]),
        currentFranchise: types.optional(
            types.late(() => _Franchise),
            {},
            [null, undefined]
        ),
        franchises: types.array(types.late(() => _Franchise)),
        brands: types.array(types.late(() => _Brand)),

        // read-only stuff
        readOnly: types.optional(types.boolean, false, [null, undefined]),
        canManagePersonalData: types.optional(types.boolean, true, [null, undefined]),
        personalDataAccessEndAt: types.optional(types.string, '', [null, undefined, false]),
        secondaryEmail: types.optional(types.string, '', [null, undefined, false]),
        forwardEmail: types.optional(types.string, '', [null, undefined, false]),
        type: types.optional(types.enumeration('Type', ['NATURAL', 'LEGAL']), 'NATURAL', [null, undefined, false]),
    })
    .volatile<{
        mustChangePassword: boolean
        mustAgreeNewCgu: boolean
        twoFaKeys: string[]
        twoFaCode: string
        twoFaQrCodeImage: string
        emailToken: string
    }>(() => ({
        mustChangePassword: false,
        mustAgreeNewCgu: false,
        twoFaKeys: [],
        twoFaCode: '',
        twoFaQrCodeImage: '',
        emailToken: '',
    }))
    .views(self => ({
        get uuid(): string {
            return self.id
        },

        get hasPartner(): boolean {
            return self.partner && self.partner.uuid !== ''
        },

        get hasFranchise(): boolean {
            return self.currentFranchise && self.currentFranchise.uuid !== ''
        },

        get currentBrand() {
            if (this.hasFranchise) {
                const franchise = self.currentFranchise
                const brand = self.brands.find(b => b.uuid === franchise.brandUuid)

                return brand
            }

            if (self.brands.length === 1) {
                return self.brands[0]
            }

            return undefined
        },

        getStacks() {
            const stacks = [...self.stacks]

            const brand = this.currentBrand
            if (brand?.stacks) {
                stacks.push(...brand.stacks)
            }

            return stacks
        },

        get isNewUser(): boolean {
            const creationDate = DateTime.fromMillis(self.createdAt)
            const sevenDaysAgo = DateTime.local().minus({ days: 7 })

            return creationDate > sevenDaysAgo
        },

        get welcomePage(): string {
            if (this.isNewUser) {
                return 'welcome-message'
            }

            return 'dashboard-message'
        },

        get isLogged(): boolean {
            return self.username !== '' && self.id !== ''
        },

        get isGoogleUser(): boolean {
            return self.ssoMethod === 'google'
        },

        get isReadOnly(): boolean {
            if (!self.deleted) {
                return false
            }

            return DateTime.local() > this.blockAt
        },

        get birthDate(): DateTime | undefined {
            return self.birthdate !== '' ? DateTime.fromISO(self.birthdate) : undefined
        },

        get blockAt(): DateTime {
            return DateTime.fromMillis(self.accountBlockAt)
        },

        get personalDataAccessEndsAt() {
            return self.personalDataAccessEndAt !== '' ? DateTime.fromISO(self.personalDataAccessEndAt) : undefined
        },

        get deleteAt(): DateTime {
            return DateTime.fromMillis(self.accountDeleteAt)
        },

        get isAdmin(): boolean {
            return ['SUPERADMIN', 'ADMIN'].includes(this.role)
        },
    }))
    .actions(self => {
        return {
            reset: () => {
                applySnapshot(self, {})
            },

            refresh(user: Partial<User>) {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                if (
                    user.franchises &&
                    user.franchises.length > 0 &&
                    (!self.currentFranchise || self.currentFranchise.uuid === '')
                ) {
                    user.currentFranchise = user.franchises[0]
                } else {
                    user.currentFranchise = self.currentFranchise
                }

                applySnapshot(self, user)
            },

            /* props */
            setEmail(email: string) {
                self.email = email
            },

            setSecondaryEmail(email: string) {
                self.secondaryEmail = email
            },

            setFullname(fullname: string) {
                self.fullname = fullname
            },

            setOcrStatus(ocrStatus: string) {
                self.ocrStatus = ocrStatus
            },

            setPhone(phone: string) {
                self.phone = phone
            },

            setLocale(locale: string) {
                self.locale = locale
            },

            setUsername(username: string) {
                self.username = username
            },

            setStreet(street: string) {
                self.street = street
            },

            setZip(zip: string) {
                self.zip = zip
            },

            setNumber(number: string) {
                self.number = number
            },

            setCity(city: string) {
                self.city = city
            },

            setCountry(country: string) {
                self.country = country
            },

            setPassword(password: string) {
                self.password = password
            },

            setNewPassword(password: string) {
                self.changedPassword = password
            },

            setMustChangePassword(value: boolean) {
                self.mustChangePassword = value
            },

            getMustChangePassword(): boolean {
                return self.mustChangePassword
            },

            getMustAgreeNewCgu(): boolean {
                return self.hasNewCgu
            },

            setMustAgreeNewCgu(value: boolean) {
                self.mustAgreeNewCgu = value
            },

            setCurrentFranchise(franchise) {
                self.currentFranchise = franchise
            },

            set(key: string, value: unknown) {
                self[key] = value
            },
        }
    })
    .actions(self => {
        return {
            /* methods */
            register: flow(function* (
                username: string,
                firstname: string,
                lastname: string,
                password: string,
                locale: string,
                token: string = undefined
            ) {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                const postData = {
                    username,
                    password,
                    lastname,
                    firstname,
                    token,
                    locale,
                }

                try {
                    const data = yield post<typeof postData, Token & JsonApiUserType>('/v1/register', postData)

                    const {
                        data: { token, user },
                    } = data
                    self.refresh(user)

                    root.setToken(token)
                } catch (err) {
                    root.error.prepare(err)
                }
            }),

            reconnect: async (uuid: string) => {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                const loginData = {
                    account: self.account.uuid,
                    uuid,
                }

                try {
                    const {
                        data: { token, user },
                    } = await post<typeof loginData, { data: { token: string; user: typeof self } }>(
                        '/v1/relogin',
                        loginData
                    )

                    self.setCurrentFranchise(undefined)
                    self.refresh(user)

                    root.setToken(token)
                    root.error.clean()
                } catch (err) {
                    root.error.prepare(err)
                }
            },

            login: async (
                username: string,
                password: string,
                rememberMe: boolean,
                newPassword: string | undefined = undefined,
                token: string = undefined
            ) => {
                const root = getRoot(self) as RootInstance
                root.error.clean()
                self.setMustChangePassword(false)

                const loginData = {
                    username,
                    password,
                    newPassword,
                    token,
                }

                try {
                    const {
                        data: { token, user, userIsReactivated },
                    } = await post<
                        typeof loginData,
                        { data: Token & JsonApiUserType & { userIsReactivated: boolean } }
                    >('/v1/login', loginData)

                    self.setMustAgreeNewCgu(user.hasNewCgu)
                    if (user.twoFa) {
                        self.refresh({
                            twoFa: user.twoFa,
                            twoFaMethod: user.twoFaMethod,
                        })
                    } else {
                        self.refresh(user)
                    }

                    if (userIsReactivated) {
                        toast(
                            'info',
                            'web_user_has_been_reactivated',
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            false
                        )
                    }

                    root.setRememberMe(rememberMe)
                    root.setToken(token)
                    root.error.clean()

                    return true
                } catch (err) {
                    root.error.prepare(err)

                    if (root.error.message === 'api_new_password_required') {
                        root.error.clean()

                        if (err instanceof HttpError) {
                            const {
                                data: { emailToken },
                            } = err.originalData as { data: { emailToken: string } }

                            self.set('emailToken', emailToken)
                            self.set('email', username)
                            self.set('password', password)
                            self.set('mustChangePassword', true)
                        } else {
                            self.setMustChangePassword(true)
                        }

                        return false
                    }

                    return false
                }
            },

            linkTo: flow(function* (token: string) {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                const postData = {
                    token,
                }
                try {
                    const {
                        data: { user },
                    } = yield post<typeof postData, { data: unknown }>('/v1/web/partners/link', postData)
                    self.refresh(user)
                } catch (err) {
                    root.error.prepare(err)
                }
            }),

            changePassword: flow(function* (username: string, password: string, token: string) {
                const root = getRoot(self) as RootInstance
                root.error.clean()
                self.setMustChangePassword(false)

                const postData = {
                    username,
                    password,
                    token,
                }

                try {
                    yield post<typeof postData, Token & JsonApiUserType>('/v1/password', postData)
                } catch (err) {
                    root.error.prepare(err)
                }
            }),

            modifyPassword: flow(function* (oldPassword: string, newPassword: string) {
                const root = getRoot(self) as RootInstance
                root.error.clean()
                self.setMustChangePassword(false)

                const postData = {
                    oldPassword,
                    newPassword,
                }

                try {
                    yield put<typeof postData, Token & JsonApiUserType>('/v1/password', postData)

                    return true
                } catch (err) {
                    root.error.prepare(err)

                    return false
                }
            }),

            recovery: flow(function* () {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                const postData = {
                    username: self.email,
                }

                try {
                    yield post<typeof postData, Token & JsonApiUserType>('/v1/recovery', postData)
                } catch (err) {
                    root.error.prepare(err)
                }
            }),

            fetchRecoveryEmailToken: flow(function* (verifyEmailToken: string) {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                const postData = {
                    newPassword: self.changedPassword,
                }

                try {
                    const data = yield put<typeof postData, JsonApiUserType>(
                        `/v1/recovery/${verifyEmailToken}`,
                        postData
                    )
                    const {
                        data: { user },
                    } = data
                    self.refresh(user)
                } catch (err) {
                    root.error.prepare(err)
                    if (root.error.message === 'app_error_not_found') {
                        root.error.setMessage('web_token_not_found')
                    }
                }
            }),

            resendVerifyEmailToken: flow(function* () {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                try {
                    yield put('/v1/verify')
                    toast('success', 'web_user_email_not_validated_resent')
                } catch (err) {
                    root.error.prepare(err)
                }
            }),

            fetchVerifyEmailToken: flow(function* (verifyEmailToken: string) {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                try {
                    const data = yield post<void, JsonApiUserType>(`/v1/verify/${verifyEmailToken}`)
                    const {
                        data: { user },
                    } = data
                    self.refresh(user)
                } catch (err) {
                    root.error.prepare(err)
                }
            }),

            useFranchise: async (uuid: string) => {
                const franchise = toJS(self.franchises.find(franchise => franchise.uuid === uuid))
                const config = mapOrJsonOptions(franchise.brandConfig)
                self.setCurrentFranchise({ ...franchise, brandConfig: config })
                window.location.reload()
            },

            jwtConnect: async () => {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                try {
                    const data = await post<never, { data: Token & JsonApiUserType }>('/v1/login/jwt/refresh')
                    const {
                        data: { user, token },
                    } = data

                    if (user.twoFa) {
                        Object.assign(self, {
                            twoFa: user.twoFa,
                            twoFaMethod: user.twoFaMethod,
                        })
                    } else {
                        self.refresh(user)
                    }
                    root.setRememberMe(true)
                    root.setToken(token)
                } catch (err) {
                    root.error.prepare(err)
                }
            },

            appleConnect: flow(function* () {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                const postData = {
                    username: self.username,
                }

                try {
                    const data = yield post<typeof postData, Token & JsonApiUserType>(
                        '/v1/login/apple/refresh',
                        postData
                    )
                    const {
                        data: { user, token, userIsReactivated },
                    } = data

                    if (user.twoFa) {
                        Object.assign(self, {
                            twoFa: user.twoFa,
                            twoFaMethod: user.twoFaMethod,
                        })
                    } else {
                        self.refresh(user)
                    }

                    if (userIsReactivated) {
                        toast(
                            'info',
                            'web_user_has_been_reactivated',
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            false
                        )
                    }

                    root.setRememberMe(true)
                    root.setToken(token)
                } catch (err) {
                    root.error.prepare(err)
                }
            }),

            linkedinConnect: flow(function* () {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                try {
                    const data = yield post<void, Token & JsonApiUserType>('/v1/login/linkedin/refresh')
                    const {
                        data: { user, token, userIsReactivated },
                    } = data
                    if (user.twoFa) {
                        Object.assign(self, {
                            twoFa: user.twoFa,
                            twoFaMethod: user.twoFaMethod,
                        })
                    } else {
                        self.refresh(user)
                    }

                    if (userIsReactivated) {
                        toast(
                            'info',
                            'web_user_has_been_reactivated',
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            false
                        )
                    }

                    root.setRememberMe(true)
                    root.setToken(token)
                } catch (err) {
                    root.error.prepare(err)
                }
            }),

            googleConnect: flow(function* (googleToken: string, fromUser: string | undefined = undefined) {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                const postData = {
                    access_token: googleToken,
                    from_user: fromUser,
                }
                try {
                    const data = yield post<typeof postData, Token & JsonApiUserType>('/v1/login/google', postData)
                    if (fromUser) {
                        // we don't want to replace existing user info
                        return
                    }

                    const {
                        data: { user, token, userIsReactivated },
                    } = data
                    if (user.twoFa) {
                        Object.assign(self, {
                            twoFa: user.twoFa,
                            twoFaMethod: user.twoFaMethod,
                        })
                    } else {
                        self.refresh(user)
                    }

                    if (userIsReactivated) {
                        toast(
                            'info',
                            'web_user_has_been_reactivated',
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            false
                        )
                    }

                    root.setRememberMe(true)
                    root.setToken(token)
                } catch (err) {
                    root.error.prepare(err)
                }
            }),

            verifyTwoFaCode: flow(function* (twoFaVerify: string, firstTimeTwoFaVerify = false) {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                interface TwoFaKeys {
                    keys: string[]
                }

                const postData = {
                    code: twoFaVerify,
                }

                const url = firstTimeTwoFaVerify ? `/v1/user/${self.id}/2fa/verify` : '/v1/login/verify'
                try {
                    const data = yield post<typeof postData, (Token & JsonApiUserType) | TwoFaKeys>(url, postData)

                    if (firstTimeTwoFaVerify) {
                        const {
                            data: { keys },
                        } = data

                        self.twoFaKeys = keys
                        self.twoFa = true
                        self.twoFaMethod = 'code'

                        toast('success', 'web_2fa_success')
                    } else {
                        const {
                            data: { user },
                        } = data
                        self.refresh(user)
                    }
                } catch (err) {
                    root.error.prepare(err)
                }
            }),

            removeTwoFa: flow(function* (twoFaVerify: string) {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                const postData = {
                    code: twoFaVerify,
                }

                try {
                    const data = yield put<typeof postData, JsonApiUserType>(`/v1/user/${self.id}/2fa`, postData)
                    const {
                        data: { user },
                    } = data
                    self.refresh(user)

                    toast('success', 'web_2fa_remove_success')
                } catch (err) {
                    root.error.prepare(err)
                }
            }),

            loadNotifications: flow(function* () {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                try {
                    const data = yield get<void, JsonApiUserType>('/v1/users/notifications/settings')
                    const {
                        data: { firebaseBlacklist },
                    } = data

                    self.firebaseBlacklist = firebaseBlacklist

                    return firebaseBlacklist
                } catch (err) {
                    root.error.prepare(err)
                }
            }),

            updateNotification: flow(function* (namespace: string, type: string, value: NotificationFrequency) {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                const postData = {
                    namespace,
                    type,
                    value,
                }

                try {
                    const data = yield post<typeof postData, JsonApiUserType>('/v1/users/notifications', postData)
                    const {
                        data: { user },
                    } = data
                    self.refresh(user)
                } catch (err) {
                    root.error.prepare(err)
                }
            }),

            update: flow(function* () {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                const postData = {
                    fullname: self.fullname,
                    email: self.email,
                    secondaryEmail: self.secondaryEmail,
                    phone: self.phone,
                    locale: self.locale,
                    street: self.street,
                    zip: self.zip,
                    number: self.number,
                    city: self.city,
                    country: self.country,
                    ocrStatus: self.ocrStatus,
                }

                try {
                    const data = yield put<typeof postData, JsonApiUserType>(`/v1/users/${self.id}`, postData)
                    const {
                        data: {
                            user: {
                                email,
                                secondaryEmail,
                                fullname,
                                phone,
                                street,
                                zip,
                                number,
                                city,
                                country,
                                ocrStatus,
                            },
                        },
                    } = data
                    self.phone = phone
                    self.email = email
                    self.secondaryEmail = secondaryEmail
                    self.fullname = fullname
                    self.street = street
                    self.zip = zip
                    self.number = number
                    self.city = city
                    self.country = country
                    self.ocrStatus = ocrStatus
                } catch (err) {
                    root.error.prepare(err)
                }
            }),

            initTwoFa: flow(function* () {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                interface Json2Fa {
                    image: string
                    code: string
                }
                interface JsonReturn {
                    data: Json2Fa
                }

                try {
                    const data = yield post<void, JsonReturn>(`/v1/user/${self.id}/2fa`)

                    const {
                        data: { image, code },
                    } = data
                    self.twoFaCode = code
                    self.twoFaQrCodeImage = image
                } catch (err) {
                    root.error.prepare(err)
                }
            }),

            clearTwoFaInit() {
                self.twoFaCode = ''
                self.twoFaQrCodeImage = ''
                self.twoFaKeys = []
            },

            uploadProfilePicture: flow(function* (name: string, image: Blob) {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                const formData = new FormData()
                formData.set('picture', image, name)

                interface JsonPicture {
                    picture: string
                }
                interface JsonPictureData {
                    data: JsonPicture
                }

                try {
                    const data = yield put<FormData, JsonPictureData>(`/v1/users/${self.id}/picture`, formData)

                    const {
                        data: {
                            user: { picture },
                        },
                    } = data
                    self.picture = picture
                } catch (err) {
                    root.error.prepare(err)
                }
            }),

            deleteMe: flow(function* () {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                try {
                    yield post(`/v1/users/${self.id}/remove`)

                    root.logout()
                } catch (err) {
                    root.error.prepare(err)
                }
            }),

            deleteSso: flow(function* (id: string) {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                try {
                    const data = yield del<void>(`/v1/users/${id}/ssos`)
                    const {
                        data: { user, ssos },
                    } = data
                    self.refresh(user)
                    self.ssos = ssos
                } catch (err) {
                    root.error.prepare(err)
                }
            }),

            getSsos: flow(function* () {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                try {
                    const data = yield get<void, JsonApiUserType>(`/v1/users/${self.id}/ssos`)
                    const {
                        data: { user, ssos },
                    } = data
                    self.refresh(user)
                    self.ssos = ssos
                } catch (err) {
                    root.error.prepare(err)
                }
            }),

            wsUpdate: user => {
                self.refresh(user)
            },

            refreshUser: async () => {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                try {
                    const {
                        data: { user },
                    } = await get<void, { data: JsonApiUserType }>(`/v1/users/${self.id}`)

                    self.refresh(user)
                } catch (err) {
                    root.error.prepare(err)
                }
            },

            updateWidget: flow(function* (widget: string) {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                const postData = { widget }
                try {
                    const data = yield put<typeof postData, JsonApiUserType>('/v1/web/config/widget', postData)
                    const {
                        data: { user },
                    } = data
                    self.refresh(user)
                } catch (err) {
                    root.error.prepare(err)
                }
            }),

            _toggle: async function (url: string) {
                const root = getRoot(self) as RootInstance
                //root.error.clean()

                try {
                    const data = await put<void, { data: { user: typeof self } }>(url)
                    const {
                        data: { user },
                    } = data
                    //       self.refresh(user)
                } catch (error) {
                    console.error(error)
                    root.error.prepare(error)
                }
            },

            _impersonate: async function (url: string) {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                try {
                    const {
                        data: { user, token, currentToken },
                    } = await post<void, { data: { user: typeof self; token: string; currentToken: string } }>(url)

                    root.setImpersonatingToken(currentToken)
                    root.setImpersonatingUser(toJS(self))

                    self.refresh(user)
                    root.setToken(token)
                } catch (error) {
                    root.error.prepare(error)
                }
            },

            findTrustedUser: flow(function* (q: string, otherParams?: { getPendingInvitations: boolean }) {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                interface JsonReturnContent {}
                interface JsonReturn {
                    data: JsonReturnContent
                }
                try {
                    const getData = { q, acceptedOnly: true, ...(otherParams ?? {}) }
                    const data = yield get<typeof getData, JsonReturn>('/v1/web/trusted-users', getData)

                    const {
                        data: {
                            trustedUsers,
                            trustedUsersPerFranchise,
                            trustedUsersPerBrand,
                            otherUsers,
                            pendingInvitations,
                        },
                    } = data

                    return {
                        trustedUsers,
                        trustedUsersPerFranchise,
                        trustedUsersPerBrand,
                        otherUsers,
                        pendingInvitations,
                    }
                } catch (err) {
                    root.error.prepare(err)
                }
            }),

            loadPartner: flow(function* () {
                const root = getRoot(self) as RootInstance
                root.error.clean()
                if (!self.partnerUuid || self.partnerUuid === '') {
                    return
                }
                try {
                    const data = yield get<void, { data: Partner }>(`/v1/web/partners/${self.partnerUuid}`)
                    const {
                        data: { user, partner },
                    } = data
                    self.refresh(user)
                    self.partner = partner
                } catch (err) {
                    root.error.prepare(err)
                }
            }),

            addTrustedPerson: flow(function* (email, firstname, lastname, mobile, phone, address, zip, city, country) {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                interface JsonReturnContent {}
                interface JsonReturn {
                    data: JsonReturnContent
                }
                try {
                    const postData = { email, firstname, lastname, mobile, phone, address, zip, city, country }
                    const data = yield post<typeof postData, JsonReturn>('/v1/web/trusted-users', postData)

                    const {
                        data: { trustedUser },
                    } = data

                    return trustedUser
                } catch (err) {
                    root.error.prepare(err)

                    return undefined
                }
            }),

            linkTrustedPerson: flow(function* (userUuid: string, userTypeId: string) {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                interface JsonReturnContent {}
                interface JsonReturn {
                    data: JsonReturnContent
                }
                try {
                    const postData = { userUuid, userTypeId }
                    const data = yield post<typeof postData, JsonReturn>('/v1/web/trusted-users', postData)

                    const {
                        data: { trustedUser },
                    } = data

                    return trustedUser
                } catch (err) {
                    root.error.prepare(err)
                }
            }),

            agreeCgu: flow(function* (cguId: number) {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                try {
                    const data = yield put<void, { data: typeof self }>(`/v1/users/${self.id}/cgu/${cguId}`)
                    const {
                        data: { user },
                    } = data
                    self.refresh(user)
                } catch (err) {
                    root.error.prepare(err)
                }
            }),

            loadTribe: flow(function* () {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                try {
                    const data = yield get<void, { data: { tribe: typeof self.tribe } }>(`/v1/users/${self.id}/tribe`)
                    const {
                        data: { tribe },
                    } = data
                    self.tribe = tribe
                } catch (err) {
                    root.error.prepare(err)
                }
            }),

            getTribe: flow(function* (tribeId: string, code: string) {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                try {
                    const getData = { code }
                    const data = yield get<typeof getData, { data: { user: typeof self } }>(
                        `/v1/users/${tribeId}/tribe/infos`,
                        getData
                    )
                    const {
                        data: { user },
                    } = data

                    return user
                } catch (err) {
                    root.error.prepare(err)
                }
            }),

            confirmTribe: flow(function* (tribeId: string, code: string) {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                try {
                    const getData = { code }
                    const data = yield post<typeof getData, { data: { tribe: typeof self.tribe } }>(
                        `/v1/users/${tribeId}/tribe/infos`,
                        getData
                    )
                    const {
                        data: { tribe },
                    } = data
                    self.tribe = tribe

                    return true
                } catch (err) {
                    // root.error.prepare(err)

                    return err.message
                }
            }),
        }
    })
    .actions(self => {
        return {
            inviteTribeMember: flow(function* (email: string, force: boolean) {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                try {
                    const postData = { email }
                    const queryData = force ? `?force=true` : ''
                    const data = yield post<typeof postData, { data: { tribe: typeof self.tribe } }>(
                        `/v1/users/${self.id}/tribe/invite${queryData}`,
                        postData
                    )
                    const {
                        data: { tribe },
                    } = data
                    self.tribe = tribe
                } catch (err) {
                    root.error.prepare(err)
                }
            }),

            removeTribeMember: flow(function* (email?: string, uuid?: string) {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                try {
                    const data = { email, uuid }
                    const d = Object.keys(data).reduce((prev, curr) => {
                        const value = data[curr]
                        if (value) {
                            prev[curr] = value
                        }

                        return prev
                    }, {})

                    const params = new URLSearchParams(d)
                    yield del<typeof data>(`/v1/users/${self.id}/tribe?${params.toString()}`)

                    yield self.loadTribe()
                } catch (err) {
                    root.error.prepare(err)
                }
            }),

            leaveTribe: flow(function* () {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                try {
                    yield del(`/v1/users/${self.tribe.uuid}/tribe/infos`)

                    return true
                } catch (err) {
                    root.error.prepare(err)

                    return false
                }
            }),

            prepareLinkAccount: async () => {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                try {
                    if (!self.account.uuid) {
                        const data = await put<void, { data: { account: string } }>(`/v1/users/${self.id}/account`)

                        const {
                            data: { account },
                        } = data

                        root.storeUserAccount(account)
                    } else {
                        root.storeUserAccount(self.account.uuid)
                    }
                    root.logout()
                } catch (err) {
                    root.error.prepare(err)
                }
            },

            removeLinkAccount: async (uuid: string) => {
                const root = getRoot(self) as RootInstance
                root.error.clean()

                try {
                    await del(`/v1/users/${self.id}/account/${uuid}`)
                    await self.refreshUser()
                } catch (err) {
                    root.error.prepare(err)
                }
            },

            linkAccountIfNeeded: async () => {
                const root = getRoot(self) as RootInstance
                root.error.clean()
                const linkedAccount = root.getUserAccount()
                if (!linkedAccount) {
                    return
                }

                try {
                    const formData = { account: linkedAccount }
                    await post<typeof formData, { data: { account: string } }>(`/v1/users/${self.id}/account`, formData)
                    await self.refreshUser()
                } catch (err) {
                    root.error.prepare(err)
                }
            },

            impersonate: async function (id: string) {
                return await self._impersonate(`/v1/bo/users/${id}/impersonate`)
            },

            brandImpersonate: async function (id: string, userId: string) {
                return await self._impersonate(`/v1/web/franchises/${id}/impersonate/${userId}`)
            },

            toggle: async function () {
                return await self._toggle(`/v1/bo/users/${self.id}/toggle`)
            },
            sendRecoveryEmail: async function (uuid: string) {
                await post(`/v1/bo/users/${uuid}/recovery-email`)
            },
            partnerToggle: async function (partnerId: string) {
                await self._toggle(`/v1/web/partners/${partnerId}/users/${self.id}/toggle`)
            },
        }
    })

export interface User extends Instance<typeof _User> {}
