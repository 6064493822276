/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import NoMatch from 'components/dataroom/no-match'
import type { ReactNode } from 'react'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import { useMst } from '../stores/store'

interface FC {
    children?: ReactNode
}

export const Redirect = ({ to }: { to: string }) => {
    const params = useParams()

    let url = to
    for (const key of Object.keys(params)) {
        url = url.replace(`:${key}`, params[key])
    }

    window.location.href = url

    return <></>
}

export const GuestRoute = ({ children }: FC) => {
    const { isLogged } = useMst()
    const location = useLocation()

    if (isLogged) {
        return <Navigate to="/dashboard" state={{ from: location }} />
    }

    return <>{children}</>
}

export const PrivateRoute = ({ children }: FC) => {
    const { isLogged } = useMst()
    const location = useLocation()

    if (!isLogged) {
        return <Navigate to="/login" state={{ from: location }} />
    }

    return <>{children}</>
}

export const PersonalDataAccessCheck = ({ children }: FC) => {
    const { isLogged, user } = useMst()

    if (isLogged && !user.canManagePersonalData) {
        return <NoMatch />
    }

    return <>{children}</>
}

export const AdminRoute = ({ children }: FC) => {
    const { isLogged, user } = useMst()
    const location = useLocation()

    if (!isLogged || !user.isAdmin) {
        return <Navigate to="/login" state={{ from: location }} />
    }

    return <>{children}</>
}

export const PartnerRoute = ({ children }: FC) => {
    const { isLogged, user } = useMst()
    const location = useLocation()
    const { id } = useParams()

    const hasAccess = isLogged && (user.managingPartners.find(p => p.uuid === id) !== undefined || user.isAdmin)

    if (!hasAccess) {
        return <Navigate to="/login" state={{ from: location }} />
    }

    return <>{children}</>
}

export const BrandRoute = ({ children }: FC) => {
    const { isLogged, user } = useMst()
    const location = useLocation()
    const { id } = useParams()

    const hasAccess = isLogged && (user.brands.find(b => b.uuid === id) !== undefined || user.isAdmin)

    if (!hasAccess) {
        return <Navigate to="/login" state={{ from: location }} />
    }

    return <>{children}</>
}

export const BrandFranchiseRoute = ({ children }: FC) => {
    const { isLogged, user } = useMst()
    const location = useLocation()
    const { id } = useParams()

    const hasAccess = isLogged && (user.franchises.find(f => f.uuid === id) !== undefined || user.isAdmin)

    if (!hasAccess) {
        return <Navigate to="/login" state={{ from: location }} />
    }

    return <>{children}</>
}
