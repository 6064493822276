import ViaTreasy from 'assets/via-treasy'
import { getFromQuery } from 'core/use-query'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Partner } from 'stores/partners'
import validator from 'validator'
import lock from '../../assets/login/register-lock.svg'
import woman from '../../assets/login/register-woman.svg'
import { useMst } from '../../stores/store'
import { passwordRegex } from '../../stores/users'
import Loader from '../loader'
import SecurePassword from '../shared/secure-password'
import LoginApple from './buttons/login-apple'
import LoginGoogle from './buttons/login-google'
import LoginLinkedIn from './buttons/login-linkedin'
import TwoPart from './two-part'

const Join = observer(() => {
    const { isLogged, user, error, partners } = useMst()
    const token = getFromQuery('token')
    const [partner, setPartner] = useState<Partner>()
    const [linked, setLinked] = useState<boolean>(false)

    const { t, i18n } = useTranslation()
    const maxPages = 3
    const [page, setPage] = useState<number>(1)
    const [cguChecked, setCguChecked] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [email, setEmail] = useState<string>('')
    const [firstname, setFirstname] = useState<string>('')
    const [lastname, setLastname] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')

    const toggleCguChecked = () => setCguChecked(!cguChecked)

    const canAddPage2 = [!validator.isEmpty(firstname), !validator.isEmpty(lastname), validator.isEmail(email)].reduce(
        (previous, current) => previous && current,
        true
    )

    const canRegister =
        !isLoading &&
        [validator.matches(password, passwordRegex), password === confirmPassword, cguChecked].reduce(
            (previous, current) => previous && current,
            true
        )

    const reset = () => {
        error.clean()
        setFirstname('')
        setLastname('')
        setEmail('')
        setPage(1)
    }

    const register = async () => {
        setIsLoading(true)

        await user.register(email, firstname, lastname, password, i18n.language, token)

        setIsLoading(false)
        if (error.hasError) {
            return
        }

        setPage(3)
    }

    const linkAccount = async () => {
        setIsLoading(true)
        await user.linkTo(token)
        setPartner(partner)
        setIsLoading(false)
        setLinked(true)
    }

    const loadPartner = async () => {
        setIsLoading(true)
        const partner = await partners.loadPartnerByToken(token)
        if (partner) {
            setPartner(partner)
        }
        setIsLoading(false)
    }
    useEffect(() => {
        if (token) {
            loadPartner()
        }
    }, [token])

    if (!partner && isLoading) {
        return <Loader />
    }

    return (
        <TwoPart
            lodoDiv={
                partner ? (
                    <div className="flex w-80 flex-wrap items-center justify-between p-4 ">
                        <div className="flex grow justify-start">
                            <img src={partner.logo} className="m-auto max-h-20" alt="Treasy" />
                        </div>
                        <div className="flex grow justify-end">
                            <ViaTreasy className="text-[#2c2a2c]" />
                        </div>
                    </div>
                ) : undefined
            }
            image={woman}
            part="register"
            subTitle={
                partner ? (
                    <Trans i18nKey="web_register_partner" values={{ partner: partner.name }}>
                        web_register_partner
                    </Trans>
                ) : (
                    t('web_register_partner_error')
                )
            }
            page={page}
            maxPages={partner ? maxPages : 1}
            bottomChildren={
                !isLogged &&
                partner &&
                page === 1 && (
                    <>
                        <span className="mx-1">{t('web_login_page_connect_sso')}</span>
                        <LoginGoogle />
                        <LoginApple />
                        <LoginLinkedIn />
                    </>
                )
            }>
            {!partner ? (
                <></>
            ) : (
                <>
                    {isLogged ? (
                        <div className="mt-8 flex w-full grow flex-col items-center justify-center text-center">
                            <h3 className="my-8 text-2xl font-bold md:text-3xl">{t('web_partner_join_title')}</h3>
                            <p className="my-8">
                                <Trans i18nKey="web_partner_join" values={{ partner: partner.name }}>
                                    web_partner_join
                                </Trans>
                            </p>

                            {linked ? (
                                <>
                                    <p className="my-8">
                                        <Trans i18nKey="web_partner_linked">web_partner_linked</Trans>
                                    </p>
                                    <p className="my-4 text-center">
                                        <Link to="/dataroom" className="btn px-8">
                                            {t('web_register_page_3_enter')}
                                        </Link>
                                    </p>
                                </>
                            ) : (
                                <p className="my-4 text-center">
                                    <button className="btn px-8" disabled={isLoading} onClick={() => linkAccount()}>
                                        {isLoading && (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="mr-2 h-4 w-4 animate-spin"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                strokeWidth={2}>
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                                                />
                                            </svg>
                                        )}
                                        {isLoading ? t('web_join_page_2_loading') : t('web_partner_join_confirm')}
                                    </button>
                                </p>
                            )}
                        </div>
                    ) : page === 1 ? (
                        <div className="mt-8 flex w-full grow flex-col items-center justify-center">
                            <h3 className="text-2xl font-bold md:my-8 md:text-3xl">{t('web_register_page_1_title')}</h3>
                            <div className="flex w-3/4 flex-col">
                                <label className="mt-4 uppercase">
                                    {t('web_register_page_1_email_address')} <span className="text-christine">*</span>
                                </label>
                                <input
                                    type="email"
                                    placeholder={t('web_register_page_1_email_address_placeholder')}
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                                <label className="mt-4 uppercase">
                                    {t('web_register_page_1_lastname')} <span className="text-christine">*</span>
                                </label>
                                <input
                                    type="email"
                                    placeholder={t('web_register_page_1_lastname_placeholder')}
                                    value={lastname}
                                    onChange={e => setLastname(e.target.value)}
                                />
                                <label className="mt-4 uppercase">
                                    {t('web_register_page_1_firstname')} <span className="text-christine">*</span>
                                </label>
                                <input
                                    type="email"
                                    placeholder={t('web_register_page_1_firstname_placeholder')}
                                    value={firstname}
                                    onChange={e => setFirstname(e.target.value)}
                                />

                                {error.hasError && (
                                    <p className="my-4 text-center text-christine">{t(error.message)}</p>
                                )}

                                <p className="my-4 mb-5 text-center md:mb-14">
                                    <button className="btn px-8" disabled={!canAddPage2} onClick={() => setPage(2)}>
                                        {t('web_register_page_1_continue')}
                                    </button>
                                </p>
                                <p className="mb-3 text-center text-lg font-bold md:mb-6">
                                    {t('web_register_page_1_already_have_treasy')}
                                </p>
                                <p className="text-center md:mb-11">
                                    <Link className="btn px-9" to={`/login?token=${token}`}>
                                        {t('web_register_page_1_login_me_link')}
                                    </Link>
                                </p>
                            </div>
                        </div>
                    ) : page === 2 ? (
                        <>
                            <div className="mt-8 flex w-full grow flex-col items-center justify-center">
                                <h3 className="my-8 text-2xl font-bold md:text-3xl">
                                    {t('web_register_page_2_title')}
                                </h3>
                                <div className="flex w-3/4 flex-col">
                                    <label className="uppercase">
                                        {t('web_register_page_2_password')} <span className="text-christine">*</span>
                                    </label>
                                    <SecurePassword value={password} onChange={value => setPassword(value)} />
                                    <p className="mb-8 text-sm text-regent-gray">
                                        {t('web_register_page_2_password_help')}
                                    </p>

                                    <label className="uppercase">
                                        {t('web_register_page_2_confirm_password')}{' '}
                                        <span className="text-christine">*</span>
                                    </label>
                                    <SecurePassword
                                        value={confirmPassword}
                                        onChange={value => setConfirmPassword(value)}
                                    />

                                    <label className="mb-8 mt-4 flex items-center" htmlFor="cgu">
                                        <input
                                            type="checkbox"
                                            className="border-atomic-tangerine text-atomic-tangerine"
                                            checked={cguChecked}
                                            onChange={() => toggleCguChecked()}
                                        />
                                        <span className="ml-2">
                                            <Trans i18nKey="web_register_page_2_i_have_read_cgu">
                                                connaissance
                                                <a
                                                    href="https://www.treasy.fr/?page_id=4856"
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    CGU
                                                </a>
                                                et je les accepte.
                                            </Trans>
                                        </span>
                                    </label>

                                    {error.hasError && (
                                        <p className="my-4 text-center text-christine">{t(error.message)}</p>
                                    )}

                                    <p className="my-4 text-center">
                                        <button className="btn px-8" disabled={!canRegister} onClick={() => register()}>
                                            {isLoading && (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="mr-2 h-4 w-4"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth={2}>
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                                                    />
                                                </svg>
                                            )}
                                            {isLoading
                                                ? t('web_register_page_2_loading')
                                                : t('web_register_page_2_continue')}
                                        </button>
                                    </p>
                                    <p className="text-center">
                                        <span className="cursor-pointer text-christine" onClick={() => reset()}>
                                            {t('web_register_page_2_back')}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className="flex grow p-8">
                                <div className="self-end">
                                    <div className="flex items-center">
                                        <div className="m-2 mr-4 flex h-8 w-8 items-center justify-center rounded-50 bg-atomic-tangerine text-white">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-4 w-4"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                strokeWidth={2}>
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                                                />
                                            </svg>
                                        </div>
                                        <div className="flex">
                                            <Trans i18nKey="web_register_page_2_branding">
                                                web_register_page_2_branding
                                            </Trans>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : page === 3 ? (
                        <div className="mt-8 flex w-full grow flex-col items-center justify-center text-center">
                            <h3 className="my-8 text-2xl font-bold md:text-3xl">{t('web_register_page_3_title')}</h3>
                            <p className="my-8">
                                <Trans i18nKey="web_register_page_3_email_desc">web_register_page_3_email_desc</Trans>
                            </p>
                            <img src={lock} alt="" className="my-8" />
                            <p className="my-4 text-center">
                                <Link to="/dataroom" className="btn px-8">
                                    {t('web_register_page_3_enter')}
                                </Link>
                            </p>
                            <p className="my-8 text-sm text-regent-gray">
                                <Trans i18nKey="web_register_page_3_no_email">web_register_page_3_no_email</Trans>
                            </p>
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </TwoPart>
    )
})

export default Join
